body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /* font-size: 16px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen (max-height: 20rem) {
  }
}

textarea {
    font-family: 'Inter', sans-serif;
}

code {
  font-family: 'Consolas', 'Courier New', monospace;
}
